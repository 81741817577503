import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './App.css'
import RootLayout from './app/RootLayout';
import HomePage from './app/HomePage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { index: true, element: <HomePage /> }
        ]
    }
]);

export default function App() {
    return <RouterProvider router={router} />
}
