import { Outlet } from "react-router";
import { Media, MediaContextProvider } from "../../features/Media";
import MainNavigationMobile from "./MainNavigationMobile";
import MainNavigationDesktop from "./MainNavigationDesktop";


export default function RootLayout() {

    return (
        <>
            <MediaContextProvider>
                <Media at='mobile'>
                    <MainNavigationMobile>
                        <Outlet/>
                    </MainNavigationMobile>
                </Media>
                <Media greaterThan='mobile'>
                    <MainNavigationDesktop>
                        <Outlet/>
                    </MainNavigationDesktop>
                </Media>
            </MediaContextProvider>
        </>
    );
}